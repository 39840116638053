import { Outlet, Link, NavLink } from "react-router-dom";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { useWeb3ModalAccount } from '@web3modal/ethers5/react'
import { useEffect } from "react";
import switchChain from "./switchChain";


const Navs = () => {
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();


 
  // useEffect(() => {
  //    if(chainId !==95){
  //     switchChain()
  //    }
  // }, [chainId])
  


  return (
    <>
      <nav className="menu-nav">
        <div className="logo">
          <Link to="/">
            <img
              src="assets/img/logo/logo.png"
              alt="Logo"
              style={{ width: "84px" }}
            />
          </Link>
        </div>
        <div className="navbar-wrap main-menu d-lg-flex">
          <ul className="navigation m-0">
            <li className="active">
              <NavLink
                to="https://katanainu.com/"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Katana Inu
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://kainu.io/"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Marketplace
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://katanainu.com/the-game"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Why Katana Inu
              </NavLink>
            </li>

            <li>
              <NavLink
                to="https://play.katanainu.com/"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Play
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://katanainu.gitbook.io/2/"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                NFT Paper
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://katanainu.com/assets/resources/katanainuwhitepaper.pdf"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                WhitePaper
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="header-action">
          <ul>
            <li className="header-btn ">
              <NavLink to="/" className="g-hover  btnd">
                <img src="assets/img/images/okx.png" alt="OKX" />
              </NavLink>
            </li>
            {isConnected ? (
              <li className="header-btn g-hover" onClick={() => open()}>
                <NavLink to="/" className="g-hover  btnd">
                {address?.slice(0, 5) + "..." + address?.slice(38, 42)}
                </NavLink>
              </li>
            ) : (
              <li className="header-btn g-hover" onClick={() => open()}>
                <NavLink to="/" className="g-hover  btnd">
                  Connect Wallet
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export default Navs;
